import React from 'react'
import { useSelector } from 'react-redux'
import { selectUserIsLoged } from '../../redux/user/selectors'

const VERSION = process.env.REACT_APP_VERSION

const Footer = () => {
  const isloged = useSelector(selectUserIsLoged)

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  // <!-- https://designsystem.gov.cz/pl/?p=viewall-organisms--01-gov-footer -->
  return (
    <footer className='gov-container u-bg-color--grey-dark gov-footer'>
      <div className='gov-container__content'>
        <section className='gov-footer__upper u-screen--only'>
          <div className='gov-footer__col'>
            <h3 className='gov-footer__headline'>Uživatelská podpora</h3>
            <ul className='gov-list--plain'>
              <li className='gov-footer__li-supp'>
                <span aria-hidden='true' className='gov-icon gov-icon--mail gov-footer__icon ' />
                <a style={{ fontWeight: 'bold' }} className='gov-footer__supp' href='mailto:smlouvy.podpora@idsk.cz'>
                  smlouvy.podpora@idsk.cz
                </a>
              </li>
            </ul>
          </div>
          {isloged && (
            <div className='gov-footer__col'>
              <h3 className='gov-footer__headline'>&nbsp;</h3>
              <ul className='gov-list--plain'>
                <li className='gov-footer__li-supp'>
                  <a
                    href='/download/stck_uzivatelska_prirucka_obce_1v0.pdf'
                    target='_blank'
                    className='gov-footer__supp'
                  >
                    Manuál ke stažení (PDF)
                  </a>
                </li>
                <li className='gov-footer__li-supp'>
                  <a href='/download/idsk-esigning-app-1.0.13.msi' target='_blank' className='gov-footer__supp'>
                    Podpisová aplikace (MS Windows)
                  </a>
                </li>
              </ul>
            </div>
          )}
        </section>
        <hr />
        <section className='gov-footer__lower'>
          <p className='gov-footnote'>Verze {VERSION}</p>
          <p className='gov-footnote'>
            Uživatelská podpora (po – pá od 9:00 - 16:00):{' '}
            <a href='tel:+420607001479' style={{ color: 'inherit', fontSize: 'inherit' }}>
              +420 607 001 479
            </a>
          </p>
        </section>
        {isloged && (
          <button
            onClick={goToTop}
            className='gov-button gov-button--primary-outlined gov-button--inversed gov-footer__scroll-up gov-js--scroll-up'
            aria-labelledby='fo-scrollBtn'
          >
            <span id='fo-scrollBtn' className='u-sr-only gov-button__label'>
              Zpět nahoru
            </span>
          </button>
        )}
      </div>
    </footer>
  )
}

export default Footer
